import type { ButtonCircleProps } from '@aph/ui/components/button-circle/button-circle';
import { ButtonCircle } from '@aph/ui/components/button-circle/button-circle';

interface PaginationButtonProps extends Omit<ButtonCircleProps, 'icon' | 'onClick'> {
  onPaginate: () => void;
  direction: 'prev' | 'next';
  'aria-label': string;
}

export const PaginationButton = ({
  onPaginate,
  direction,
  variant = 'white',
  size = 'medium',
  ...props
}: PaginationButtonProps) => (
  <ButtonCircle
    data-testid="PaginationButton"
    onClick={onPaginate}
    size={size}
    variant={variant}
    icon={direction === 'prev' ? 'ArrowheadLeft' : 'ArrowheadRight'}
    {...props}
  />
);
