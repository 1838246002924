import { urlify } from '@aph/utilities/slug';
import type { IBrandPage, IGenericPage, INavigation, ITipsAndAdvicePage } from '~/contentful/types';
import { type UnknownContentType, isContentPage } from '~/contentful/utilities/is-content-type';
import { BrandList } from '../brand-list/brand-list.component';
import { ContentCardListing } from '../content-card-listing/content-card-listing';
import { EditorialListing } from '../editorial-listing/editorial-listing';

const isTipsAndAdvicePageLinks = (
  pages: Array<UnknownContentType>,
): pages is Array<ITipsAndAdvicePage> => {
  return pages?.every((page) => page.sys.contentType.sys.id === 'tipsAndAdvicePage');
};

const isBrandPageLinks = (pages: Array<UnknownContentType>): pages is Array<IBrandPage> => {
  return pages?.every((page) => page.sys.contentType.sys.id === 'brandPage');
};

const isContentPageLinks = (pages: Array<UnknownContentType>): pages is Array<IGenericPage> => {
  return pages?.every(isContentPage);
};

export const NavigationListing = ({ fields }: INavigation) => {
  const { pages, heading, variant } = fields;

  const isContentCardVariant = variant === 'Content Card';

  if (pages) {
    if (isTipsAndAdvicePageLinks(pages)) {
      return (
        <EditorialListing
          title={heading}
          items={pages?.map((page) => ({
            id: page.sys.id,
            title: page.fields.title,
            image: page.fields.listingImage ?? page.fields.image,
            category: page.fields.parent.fields.title,
            href: urlify(page.fields.slug),
          }))}
        />
      );
    }

    if (isBrandPageLinks(pages)) {
      return <BrandList title={heading} brands={pages} />;
    }

    if (isContentPageLinks(pages)) {
      if (isContentCardVariant) {
        return (
          <ContentCardListing
            title={heading}
            items={pages?.map((page) => ({
              title: page.fields.navigationTitle ?? page.fields.title,
              image: page.fields.navigationImage,
              description: page.fields.navigationDescription,
              href: urlify(page.fields.slug),
            }))}
          />
        );
      }
    }

    // for now
    return null;
  }

  // for now
  return null;
};
