import { Stack } from '@mui/material';
import { Typography } from '@aph/ui/components/typography/typography';
import type { IImage } from '../../types';
import { ContentfulImage } from '../next-contentful-image/next-contentful-image';

export const Image = ({ fields }: IImage) => {
  const { caption, image, altText } = fields;

  return (
    <Stack gap={1.5}>
      <Stack overflow="hidden" borderRadius={{ xs: 4, sm: 6 }}>
        <ContentfulImage
          src={image.fields.file.url}
          alt={altText || image.fields.description}
          height={image.fields.file.details.image?.height}
          width={image.fields.file.details.image?.width}
          sizes="100vw"
          style={{
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
          }}
        />
      </Stack>
      {caption && (
        <Typography data-testid="ImageCaption" typography="subHeading" color="text-subtle">
          {caption}
        </Typography>
      )}
    </Stack>
  );
};
