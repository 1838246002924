import { useEffect, useRef, useState } from 'react';
import { DottedPagination } from '@aph/components/common/dotted-pagination';
import type { IImageLink } from '../../types';
import { ImageLink } from '../image-link/image-link';

type BannerProps = {
  imageLinks: IImageLink[];
  slideIntervalInSeconds: number;
  onClick: (image: IImageLink, index: number) => void;
  onChange: (image: IImageLink, index: number) => void;
};

export const Banner = ({ imageLinks, slideIntervalInSeconds, onClick, onChange }: BannerProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const timer = useRef<NodeJS.Timeout | null | void>(null);
  const maxIndex = imageLinks.length - 1;
  const firstIndex = 0;
  const prevIndex = currentIndex <= firstIndex ? maxIndex : currentIndex - 1;
  const nextIndex = currentIndex >= maxIndex ? firstIndex : currentIndex + 1;
  const hasOneImage = imageLinks.length === 1;

  const onPaginate = (index: number) => {
    setCurrentIndex(index);
  };

  const resetTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  };

  useEffect(() => {
    if (hasOneImage) {
      return () => null;
    }
    resetTimer();
    timer.current = setTimeout(() => setCurrentIndex(nextIndex), slideIntervalInSeconds * 1000);
    return () => resetTimer();
  }, [hasOneImage, nextIndex, slideIntervalInSeconds]);

  useEffect(() => {
    onChange(imageLinks[currentIndex], currentIndex);
  }, [currentIndex, onChange, imageLinks]);

  return (
    <div className="mb-2 flex flex-col">
      <div>
        {imageLinks.map((image, index) => {
          const isActive = currentIndex === index;
          return (
            <div
              className={`relative float-left mr-[-100%] w-full ${isActive ? 'z-20 opacity-100' : 'z-10 opacity-0'} transition-opacity duration-500 ease-in-out`}
              key={image.sys.id}
            >
              <ImageLink
                href={image.fields.link}
                src={image.fields.image.fields.file.url}
                alt={image.fields.image.fields.description || image.fields.title}
                height={image.fields.image.fields.file.details.image?.height}
                width={image.fields.image.fields.file.details.image?.width}
                sizes="100vw"
                priority={index === 0}
                onClick={() => onClick(image, index)}
              />
            </div>
          );
        })}
      </div>
      {hasOneImage ? null : (
        <div className="z-50 -mt-7">
          <DottedPagination
            hasDarkBackground
            decreasePage={() => onPaginate(prevIndex)}
            increasePage={() => onPaginate(nextIndex)}
            amountOfPages={imageLinks.length}
            currentPageIndex={currentIndex}
          />
        </div>
      )}
    </div>
  );
};
